import React, { useState, useRef } from "react"
import { navigate } from "gatsby"
import classNames from "classnames"

function ContactForm({ className, bg }) {
  const formRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    hearAboutUs: "",
    requestAppointment: "no",
    streetAddress: "",
    city: "",
    state: "",
    zip: "",
    preferredTime: "",
    preferredDay: "",
    additionalInfo: ""
  })

  const handleChange = e => {
    const { name, value, type, selectedOptions } = e.target

    if (type === "select-multiple") {
      const values = Array.from(selectedOptions, option => option.value)
      setFormData({ ...formData, [name]: values })
    } else {
      setFormData({ ...formData, [name]: value })
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    const formFields = new FormData()

    for (var key in formData) {
      formFields.append(key, formData[key])
    }

    setIsLoading(true)
    fetch("https://getform.io/f/bjjexmrb", {
      method: "POST",
      body: formFields,
      headers: {
        Accept: "application/json"
      }
    })
      .then(response => {
        navigate("/thank-you/")
        setIsLoading(false)
      })
      .catch(error => {
        console.error(error)
        setIsLoading(false)
      })
  }

  const mainClasses = classNames("form", className, { bg })
  return (
    <>
      <form
        ref={formRef}
        className={mainClasses}
        name="contact-form"
        id="contact-form"
        onSubmit={handleSubmit}>
        <div className="form__group-2">
          <div>
            <label className="required">First Name</label>
            <input
              required
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
            />
          </div>

          <div>
            <label className="required">Last Name</label>
            <input
              required
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
            />
          </div>
        </div>

        <div>
          <label className="required">Email</label>
          <input
            required
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>

        <div>
          <label className="required">Phone</label>
          <input
            required
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>

        <div>
          <label className="required">Message</label>
          <textarea
            required
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
        </div>

        <div>
          <label className="required">How did you hear about us?</label>
          <select
            required
            name="hearAboutUs"
            value={formData.hearAboutUs}
            onChange={handleChange}>
            <option value="">Select one</option>
            <option value="Facebook">Facebook</option>
            <option value="Instagram">Instagram</option>
            <option value="Google">Google</option>
            <option value="Friend/Word of Mouth">Friend/Word of Mouth</option>
            <option value="Referral">Referral</option>
            <option value="Billboard">Billboard</option>
            <option value="School Event">School Event</option>
            <option value="other">Other</option>
          </select>
        </div>

        <div>
          <label>Request Appointment</label>
          <label>
            <input
              type="radio"
              name="requestAppointment"
              value="yes"
              checked={formData.requestAppointment === "yes"}
              onChange={handleChange}
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              name="requestAppointment"
              value="no"
              checked={formData.requestAppointment === "no"}
              onChange={handleChange}
            />
            No
          </label>
        </div>

        {formData.requestAppointment === "yes" && (
          <>
            <div>
              <label>Street Address</label>
              <input
                type="text"
                name="streetAddress"
                value={formData.streetAddress}
                onChange={handleChange}
              />
            </div>

            <div className="form__group-3">
              <div>
                <label>City</label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label>State</label>
                <input
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label>Zip</label>
                <input
                  type="text"
                  name="zip"
                  value={formData.zip}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div>
              <label>Preferred Appointment Time</label>
              <select
                name="preferredTime"
                value={formData.preferredTime}
                onChange={handleChange}>
                <option value="">Select one</option>
                <option value="morning">Morning</option>
                <option value="afternoon">Afternoon</option>
                <option value="no preference">No Preference</option>
              </select>
            </div>

            <div>
              <label>Preferred Appointment Day</label>
              <select
                name="preferredDay"
                value={formData.preferredDay}
                onChange={handleChange}>
                <option value="">Select one</option>
                <option value="monday">Monday</option>
                <option value="tuesday">Tuesday</option>
                <option value="wednesday">Wednesday</option>
                <option value="thursday">Thursday</option>
                <option value="friday">Friday</option>
                <option value="no preference">No Preference</option>
              </select>
            </div>

            <div>
              <label>Purpose for Appointment & Additional Information</label>
              <textarea
                name="additionalInfo"
                value={formData.additionalInfo}
                onChange={handleChange}
              />
            </div>
          </>
        )}

        <button
          disabled={isLoading}
          type="submit"
          className="standard-button contained">
          {isLoading ? <span className="loader"></span> : "Submit"}
        </button>
      </form>
    </>
  )
}

export default ContactForm
